/**
 * Combined by jsDelivr.
 * Original files:
 * - /gh/lrsjng/jquery-qrcode@0.18.0/dist/jquery-qrcode.min.js
 * - /npm/blueimp-md5@2.18.0/js/md5.min.js
 * - /gh/hifocus/merger@0.27.0/assets/js/function.min.js
 * - /gh/hifocus/merger@0.27.0/assets/js/font_974919_xgtacm93vxp.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
